
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'zijinrcb',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public async next () {
    const data:any = {
      Token: getCurrentQuery('token') || ''
    }
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    try {
      const zijinrcb = await httpHelper.getAuthLogin(data, 'authorize/zijinrcb')
      this.goTo(zijinrcb, 9, 9)
      location.href = 'https://zjns-h5.benlai.com'
    } catch (err) {
      this.goTo(err, 9, 9)
      this.$toasted.show(err.msg)
    }
  }

  public async created () {
    if (getMBankAuth()) {
      const data:any = {
        Token: getCurrentQuery('token') || ''
      }
      try {
        const zijinrcb = await httpHelper.getAuthLogin(data, 'authorize/zijinrcb')
        this.goTo(zijinrcb, 9, 9)
        location.href = 'https://zjns-h5.benlai.com'
      } catch (err) {
        this.goTo(err, 9, 9)
        this.$toasted.show(err.msg)
      }
    } else {
      this.showLogin = true
    }
  }
}
